import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import "./loading.less";
import * as actions from "../actions";
// loader for api calling
class Loading extends React.Component {
  render() {
    const { isLoading } = this.props;

    return (
      <div
        className="loading"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <LoadingOutlined style={{ fontSize: 40 }} spin />
      </div>
    );
  }
}

export default connect(
  (state) => ({ isLoading: state.isLoading }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Loading));
