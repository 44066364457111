import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider, createStore, reducer } from "./redux";
import { HashRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import * as serviceWorker from "./serviceWorker";

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const Router = HashRouter;
const rootEl = document.getElementById("root");
const render = (Component) =>
  ReactDOM.render(
    <ConfigProvider locale={enUS}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ConfigProvider>,
    rootEl
  );

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
