import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// Shows the list of the user which task are complete for the reward
class completeTask extends Component {
  state = {};

  componentDidMount() {
    this.props.loadCompleteTaskList();
  }

  //Shows the list of the user
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Email",
      dataIndex: "email_id",
      key: "email_id",
      render: (_text, record) => (
        <p
          className="custom-link"
          onClick={() => {
            // const { history } = this.props;
            // history.push(`/admin/users/${record.user_id}`);
          }}
        >
          {record.email_id}
        </p>
      ),
    },
    {
      title: "Tasks",
      dataIndex: "total_tasks",
      key: "total_tasks",
      render: (_text, record) => (
        <p>{`${record.total_tasks}/${record.total_tasks}`}</p>
      ),
    },
    {
      title: "User number",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => <p>{`#${record.user_unique_id}`}</p>,
    },
    {
      title: "Account",
      dataIndex: "last_login_date",
      key: "last_login_date",
      render: (_text, record) => (
        <p>
          {`${record.account_number?.sort_code} ${record.account_number?.number}`}
        </p>
      ),
    },
    {
      title: "Winner",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => (
        <div className="btn-actions">
          <span
            onClick={(e) => {
              let data = {
                id: record._user_id,
              };
              this.props.sendReward(data).then((res) => {
                if (res?.data?.status === true) {
                  notifySuccess("Notification send successfully.");
                } else {
                  notifyError("Something went wrong. Please try again");
                }
              });
            }}
          >
            Yes
          </span>
        </div>
      ),
    },
  ];

  render() {
    const { completeTask } = this.props;
    let results = [];
    if (completeTask && completeTask.length > 0) {
      results = completeTask.map((row, index) => ({
        ...row,
        key: row._user_id,
        sr_no: index + 1,
      }));
    }
    var noOfActiveTasks = localStorage.getItem("NoOfActiveTasks");
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>{`Completed all tasks (${noOfActiveTasks})`}</h4>
          </Col>
        </Row>
        <Row className="table-users">
          <Table
            className="users-list"
            dataSource={results}
            columns={this.columns}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    completeTask: state.completeTaskList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(completeTask));
