import { Button, Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import moment from "moment";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// Shows the list of the user
class index extends Component {
  state = {
    pauseUserModal: false,
    deleteUserModal: false,
    userAction: null,
    selectedUserId: null,
    pagination: {
      current: this.props.userPageSelect,
      pageSize: 10,
    },
    vulnerableView:
      this?.props?.location?.state?.type === "vulnerable" ? true : false,
    connectedAccountsView:
      this?.props?.location?.state?.type === "connected_accounts"
        ? true
        : false,
    connectedAccountsViewData:
      this?.props?.location?.state?.type === "connected_accounts"
        ? this?.props?.location?.state?.data
        : [],
  };

  componentDidMount() {
    this.props.emptyActiveUser();
    this.props.loadUsers();
  }

  // Open the pause/unpause pop up
  handlePauseModal = (action, userId, evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
    }

    const { pauseUserModal } = this.state;
    this.setState({
      pauseUserModal: !pauseUserModal,
      userAction: action,
      selectedUserId: userId,
    });
  };

  // Shows the delete user pop up
  handleDeleteModal = (userId, evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
    }

    const { deleteUserModal } = this.state;
    this.setState({
      deleteUserModal: !deleteUserModal,
      selectedUserId: userId,
    });
  };

  // Update the user data
  submitUserAction = (action) => {
    const { selectedUserId } = this.state;
    var data = { action: action };

    this.props.updateUserAction(selectedUserId, data).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({ pauseUserModal: false, deleteUserModal: false });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadUsers();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Shows the list of the user in the table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "User Id",
      dataIndex: "u_email_id",
      key: "u_email_id",
      render: (_text, record) => (
        <p
          className="custom-link"
          onClick={() => {
            const { history } = this.props;
            history.push(`/admin/users/${record.user_id}`);
          }}
        >
          {record.user_id}
        </p>
      ),
    },
    {
      title: "Verified",
      dataIndex: "email",
      key: "email",
      render: (_text, record) => (
        <p>{record.is_email_verified ? "Yes" : "No"}</p>
      ),
    },
    {
      title: "Date joined",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p>{moment(record.date_created).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: "Last active",
      dataIndex: "last_login_date",
      key: "last_login_date",
      render: (_text, record) => (
        <p>
          {moment(record?.last_login_date || record.date_created).format(
            "DD/MM/YYYY"
          )}
        </p>
      ),
    },
    {
      title: "User number",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => <p>#{record?.user_unique_id || ""}</p>,
    },
    {
      title: "Email pref",
      dataIndex: "email",
      key: "email",
      render: (_text, record) => (
        <p>
          {record?.user_preference_setting?.marketing_preferences ? "Y" : "N"}
        </p>
      ),
    },
    {
      title: "Device",
      dataIndex: "device_name",
      key: "device_name",
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        var action = record.status === "active" ? "Pause" : "Unpause";
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => this.handleDeleteModal(record.user_id, e)}
            >
              Delete
            </span>
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => this.handlePauseModal(action, record.user_id, e)}
            >
              {action}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { users } = this.props;
    const {
      pauseUserModal,
      deleteUserModal,
      userAction,
      vulnerableView,
      connectedAccountsView,
      connectedAccountsViewData,
    } = this.state;
    let results = [];
    if (users && users.length > 0) {
      // results = users.map((row, index) => ({
      //   ...row,
      //   key: row.user_id,
      //   sr_no: index + 1,
      // }));

      if (vulnerableView) {
        results = users
          .filter((data) => Number(data.is_customer_vulnerable) === 1)
          .map((row, index) => ({
            ...row,
            key: row.user_id,
            sr_no: index + 1,
          }));
      } else if (connectedAccountsView) {
        results = users
          .filter((data) => connectedAccountsViewData.includes(data.user_id))
          .map((row, index) => ({
            ...row,
            key: row.user_id,
            sr_no: index + 1,
          }));
      } else {
        results = users.map((row, index) => ({
          ...row,
          key: row.user_id,
          sr_no: index + 1,
        }));
      }
    }
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Users ({results?.length || 0})</h4>
          </Col>
        </Row>
        <Row className="table-users">
          <Table
            className="users-list"
            dataSource={results}
            columns={this.columns}
            pagination={this.state.pagination}
            onChange={(pagination) => {
              this.setState({
                pagination: pagination.current,
              });
              this.props.updateUserCurrentPage(pagination.current);
            }}
          />
        </Row>

        <CustomModal
          className={"signup-setting-modal"}
          visible={pauseUserModal}
          onClose={this.handlePauseModal}
        >
          <p className="txt-title">{userAction} this user</p>
          {userAction === "Pause" ? (
            <p className="txt-info">
              They will not be able to sign into their SuperFi account
            </p>
          ) : (
            <p className="txt-info">
              They will be able to sign into their SuperFi account
            </p>
          )}

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction(userAction)}
          >
            {userAction}
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteUserModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this user?</p>
          <p className="txt-info">
            This change cannot be undone. All their content will be removed from
            SuperFi
          </p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    users: state.users,
    userPageSelect: state.userPageSelect,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
