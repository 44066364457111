import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";

import { notifyError, notifySuccess } from "../../common/notification";
import { TruncatedURL } from "../../common/utils";

// It's shows the card catalog info
class index extends Component {
  state = {
    addIconModal: false,
    editIconModal: false,
    deleteModal: false,
    deleteId: "",
    // addCardModal: false,
    selectedIconId: null,
    selectedIconData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    this.props.loadUserVoucherData();
  }

  handleEditIconModal = (iconId, data, evt) => {
    const { editIconModal } = this.state;

    if (iconId && data) {
      this.setState({
        editIconModal: !editIconModal,
        selectedFile: null,
        selectedImageSrc: null,
        selectedIconId: iconId,
        selectedIconData: data,
      });
    } else {
      this.setState({
        editIconModal: false,
        addIconModal: false,
        selectedFile: null,
        selectedImageSrc: null,
        selectedIconId: null,
        selectedIconData: null,
      });
    }
  };

  handleAddModal = () => {
    const { addIconModal } = this.state;
    this.setState({
      addIconModal: !addIconModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedIconId: null,
      selectedIconData: null,
    });
  };

  // Add, Edit and Delete Icons
  submitForm = (action, values) => {
    const { selectedFile, selectedIconId } = this.state;

    var formData = new FormData();
    formData.append("action", action);
    formData.append("data", JSON.stringify(values));
    formData.append("id", selectedIconId);

    if (selectedFile) {
      if (!selectedFile.type.includes("image/")) {
        notifyError("Please upload image file");
        return false;
      }
      formData.append("file", selectedFile);
    }
    this.props.updateUserVoucherInfo(formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          editIconModal: false,
          addIconModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadUserVoucherData();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  handleDeleteModal = (id) => {
    const { deleteModal } = this.state;
    this.setState({
      deleteModal: !deleteModal,
      deleteId: id,
    });
  };

  submitUserAction = () => {
    this.setState({ deleteModal: false });
    this.props
      .updateSpinTheWheelData({
        action: "delete",
        type: "voucher",
        id: this.state.deleteId,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            deleteUserModal: false,
            deleteId: "",
            editIconModal: false,
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.loadUserVoucherData();
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "user_cashback_voucher_id",
      key: "user_cashback_voucher_id",
      render: (_text, record) => (
        <p className="brand-name">{record.sr_no || ""}</p>
      ),
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (_text, record) => (
        <p className="brand-name">{record?.user_email || ""}</p>
      ),
    },
    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (_text, record) => (
        <p className="brand-name">{record?.voucher_info.brand_name || ""}</p>
      ),
    },
    {
      title: "Visual",
      dataIndex: "voucher_url",
      key: "voucher_url",
      render: (_text, record) => (
        <img
          src={record.voucher_url}
          alt=""
          style={{
            width: "60px",
            height: "45px",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "4px",
          }}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => (
        <p className="brand-name">{record.amount || ""}</p>
      ),
    },
    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_text, record) => (
        <p className="brand-name">{record.expiry_date || ""}</p>
      ),
    },
    {
      title: "Gift card number",
      dataIndex: "gift_card_number",
      key: "gift_card_number",
      render: (_text, record) => (
        <p className="brand-name">{record.gift_card_number || ""}</p>
      ),
    },
    {
      title: "PIN",
      dataIndex: "pin",
      key: "pin",
      render: (_text, record) => (
        <p className="brand-name">{record.pin || ""}</p>
      ),
    },
    {
      title: "Voucher URL",
      dataIndex: "tilio_url",
      key: "tilio_url",
      render: (_text, record) => (
        <p className="brand-name" style={{ width: "180px" }}>
          {record?.tilio_url ? <span style={{ textDecoration: 'underline' }}><TruncatedURL url={record.tilio_url} /></span> : "--"}
        </p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => {
                this.handleDeleteModal(record.user_cashback_voucher_id);
              }}
            >
              Delete
            </span>
            <span
              onClick={(e) =>
                this.handleEditIconModal(
                  record.user_cashback_voucher_id,
                  record,
                  e
                )
              }
            >
              Edit
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { userVoucherData } = this.props;
    const {
      editIconModal,
      selectedImageSrc,
      selectedIconData,
      deleteModal,
      addIconModal,
    } = this.state;
    let results = [];
    if (userVoucherData && userVoucherData.length > 0) {
      for (var i = 0; i < userVoucherData.length; i++) {
        var key = `user-cashback-voucher-${userVoucherData[i].user_cashback_voucher_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...userVoucherData[i],
          voucher_info: JSON.parse(userVoucherData[i].voucher_info),
          brand_name: JSON.parse(userVoucherData[i].voucher_info).brand_name,
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>User Vouchers</h4>
          </Col>
          <Col md={23}>
            <div>
              <Button
                className="btn-add-card"
                type="primary"
                onClick={() => this.handleAddModal()}
              >
                Add new voucher
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="card-table partner-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        {((editIconModal && selectedIconData) || addIconModal) && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editIconModal || addIconModal}
            onClose={this.handleEditIconModal}
          >
            <Form
              size="large"
              initialValues={selectedIconData}
              onFinish={(val) =>
                this.submitForm(addIconModal ? "add" : "edit", val)
              }
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit voucher info</p>
              <div className="custom-form-container">
                {addIconModal && (
                  <Form.Item name="user_email" label="User Email">
                    <Input
                      placeholder="User email"
                      type="text"
                      value={selectedIconData?.user_email || ""}
                    />
                  </Form.Item>
                )}
                <Form.Item name="brand_name" label="Brand">
                  <Input
                    placeholder="Brand name"
                    type="text"
                    value={selectedIconData?.brand_name || ""}
                    disabled={addIconModal ? false : true}
                  />
                </Form.Item>

                <Form.Item name="amount" label="Amount">
                  <Input
                    placeholder="Amount"
                    type="text"
                    value={selectedIconData?.amount || ""}
                    disabled={addIconModal ? false : true}
                  />
                </Form.Item>

                <Form.Item
                  name="expiry_date"
                  label="Expiry date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter expiry date",
                    },
                  ]}
                >
                  <Input
                    placeholder="Expiry date"
                    type="text"
                    value={selectedIconData?.expiry_date || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="gift_card_number"
                  label="Gift card number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter gift card number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Gift card number"
                    type="text"
                    value={selectedIconData?.gift_card_number || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="pin"
                  label="PIN"
                  rules={[
                    {
                      required: true,
                      message: "Please enter PIN",
                    },
                  ]}
                >
                  <Input
                    placeholder="PIN"
                    type="text"
                    value={selectedIconData?.pin || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="tilio_url"
                  label="Voucher URL"
                  rules={[
                    {
                      required: false,
                      message: "Please enter URL",
                    },
                  ]}
                >
                  <Input
                    placeholder="Voucher URL"
                    type="text"
                    value={selectedIconData?.tilio_url || ""}
                  />
                </Form.Item>

                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="Voucher visual" title="Voucher visual">
                      Voucher visual
                    </label>
                    <div className="custom-file-input">
                      <img
                        src={
                          selectedImageSrc ||
                          selectedIconData?.voucher_url ||
                          `https://via.placeholder.com/60x60?text=`
                        }
                        alt="Voucher visual"
                      />
                      <input type="file" onChange={this.handleFile} />
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this entry?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    userVoucherData: state.userVoucherData,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
