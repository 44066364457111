import { Button, Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./marketplaceIndex.less";

// Shows the list of the marketplace item clicks
class marketplaceClickInfo extends Component {
  state = {};

  componentDidMount() {
    this.props.getMarketplaceClickInfo();
  }

  //Shows the list of the user
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Marketplace item",
      dataIndex: "page_name",
      key: "page_name",
      render: (_text, record) => (
        <p
          onClick={() => {
            // const { history } = this.props;
            // history.push(`/admin/users/${record.user_id}`);
          }}
          style={{ textTransform: "capitalize" }}
        >
          {record.page_name == "splurge"
            ? "Swear Jar"
            : record.page_name == "round_up"
            ? "Round up"
            : record.page_name == "repayment_calculator"
            ? "Repayment calculator"
            : record.page_name == "consolidation_loan"
            ? "Consolidation loans"
            : record.page_name == "step_change"
            ? "StepChange"
            : record.page_name == "benefits_checker"
            ? "Benefits calculator"
            : record.page_name == "income_protection"
            ? "Income protection"
            : record.page_name == "credit_tips"
            ? "Credit tips"
            : record.page_name == "daily_savings_tracker"
            ? "Daily savings tracker"
            : record.page_name == "credit_builder"
            ? "Credit builder"
            : record.page_name == "pay_point"
            ? "Pay point"
            : record.page_name == "advice"
            ? "Advice"
            : record.page_name == "spending"
            ? "Spending"
            : record.page_name == "win_money"
            ? "Rewards"
            : record.page_name == "cash_back"
            ? "Cash back"
            : record.page_name == "health_check"
            ? "Health check"
            : record.page_name == "mortgage_roundup"
            ? "Mortgage roundup"
            : record?.page_name?.split("_")?.join(" ") || ""}
        </p>
      ),
    },
    {
      title: "No. clicks",
      dataIndex: "total_clicks",
      key: "total_clicks",
      render: (_text, record) => <p>{`${record.total_clicks}`}</p>,
    },
  ];

  render() {
    const { marketplaceClickInfo } = this.props;
    let results = [];
    if (marketplaceClickInfo && marketplaceClickInfo.length > 0) {
      const sortedData = marketplaceClickInfo.sort((a, b) => {
        const nameA = a.page_name.toUpperCase();
        const nameB = b.page_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      const summedData = {};
      sortedData.forEach((item) => {
        if (summedData.hasOwnProperty(item.page_name)) {
          summedData[item.page_name] += Number(item.total_clicks);
        } else {
          summedData[item.page_name] = Number(item.total_clicks);
        }
      });

      const mergedData = Object.keys(summedData).map((page_name) => ({
        page_name,
        total_clicks: summedData[page_name],
      }));

      results = mergedData.map((row, index) => ({
        ...row,
        key: "marketplace_item_" + `${index}`,
        sr_no: index + 1,
      }));
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Marketplace</h4>
          </Col>
        </Row>
        <Row className="table-users">
          <Table
            className="users-list"
            dataSource={results}
            columns={this.columns}
            pagination={false}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    marketplaceClickInfo: state.marketplaceClickInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(marketplaceClickInfo));
