import { Col, Row, Table, Button, Card } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";

import { notifyError, notifySuccess } from "../../common/notification";

// It's shows the card catalog info
class index extends Component {
  state = {
    paidUserModal: false,
    deleteUserModal: false,
    actionId: "",
  };

  componentDidMount() {
    this.props.loadSpinTheWheelData();
  }

  submitUserAction = (action) => {
    // const { selectedUserId } = this.state;
    // var data = { action: action };
    this.props
      .updateSpinTheWheelData({
        action: action,
        type: "spinWheel",
        id: this.state.actionId,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            deleteUserModal: false,
            paidUserModal: false,
            actionId: "",
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.loadSpinTheWheelData();
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  handlePaidModal = (id) => {
    const { paidUserModal } = this.state;
    this.setState({
      paidUserModal: !paidUserModal,
      actionId: id,
    });
  };

  // Shows the delete user pop up
  handleDeleteModal = (id) => {
    const { deleteUserModal } = this.state;
    this.setState({
      deleteUserModal: !deleteUserModal,
      actionId: id,
    });
  };

  // Add, Edit and Delete Icons
  submitForm = (action, values) => {
    // const { selectedIconId } = this.state;
    // var formData = new FormData();
    // this.props.updateMerchantIconInfo(selectedIconId, formData).then((res) => {
    //   if (res && res.data && res.data.status) {
    //     notifySuccess(res?.data?.message || "Done");
    //     this.props.loadMerchantIcons();
    //   } else {
    //     var errorMessage =
    //       res?.data?.message || res?.err?.message || "Something went wrong";
    //     notifyError(errorMessage);
    //   }
    // });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "spin_the_wheel_id",
      key: "spin_the_wheel_id",
      render: (_text, record) => (
        <p className="brand-name">{record.sr_no || ""}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_text, record) => (
        <p className="brand-name">{record.u_email_id || ""}</p>
      ),
    },
    {
      title: "Transaction",
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => (
        <p className="brand-name">
          {Math.abs(record?.transaction_data?.amount) || ""}
        </p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount_amount",
      key: "discount_amount",
      render: (_text, record) => (
        <p className="brand-name">{record.discount_amount + "%" || ""}</p>
      ),
    },
    {
      title: "Date of win",
      dataIndex: "win_date",
      key: "win_date",
      render: (_text, record) => (
        <p className="brand-name">{record.win_date || ""}</p>
      ),
    },
    {
      title: "Amount due",
      dataIndex: "amount_due",
      key: "amount_due",
      render: (_text, record) => (
        <p className="brand-name">{record?.amount_due || ""}</p>
      ),
    },
    {
      title: "Sort code",
      dataIndex: "sort_code",
      key: "sort_code",
      render: (_text, record) => (
        <p className="brand-name">{record.sort_code || ""}</p>
      ),
    },
    {
      title: "Account number",
      dataIndex: "account_number",
      key: "account_number",
      render: (_text, record) => (
        <p className="brand-name">{record.account_number || ""}</p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => {
                this.handleDeleteModal(record.user_spin_the_wheel_id);
              }}
            >
              Delete
            </span>
            <span
              style={{
                marginRight: 8,
              }}
              onClick={(e) => {
                this.handlePaidModal(record.user_spin_the_wheel_id);
              }}
            >
              Paid
            </span>
            <span
              style={{
                textDecoration: "none",
              }}
            >
              {record.is_paid == 1 ? "✅" : ""}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { spinTheWheelData, spinTheWheelCount } = this.props;
    const { deleteUserModal, paidUserModal } = this.state;
    let results = [];
    if (spinTheWheelData && spinTheWheelData.length > 0) {
      for (var i = 0; i < spinTheWheelData.length; i++) {
        var key = `user-spin-the-wheel-${spinTheWheelData[i].user_spin_the_wheel_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...spinTheWheelData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={14}>
            <h4>Spin the wheel</h4>
          </Col>

          <Col md={10} className="custom-flex-end">
            <Row className="card-wrap">
              <Card title="Total spins this month" className="custom-card">
                <h4>{spinTheWheelCount || 0}</h4>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row className="card-table partner-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>
        <CustomModal
          className={"signup-setting-modal"}
          visible={paidUserModal}
          onClose={this.handlePaidModal}
        >
          <p className="txt-title">Mark entry as paid?</p>
          <p className="txt-info">Are you sure you have paid for this entry?</p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("paid")}
          >
            Yes
          </Button>
        </CustomModal>
        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteUserModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this entry?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    spinTheWheelData: state.spinTheWheelData,
    spinTheWheelCount: state.spinTheWheelCount,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
