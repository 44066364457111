import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./subcodes.less";

// It's shows the card catalog info
class Subcodes extends Component {
  state = {
    deleteCardModal: false,
    editCardModal: false,
    addCardModal: false,
    selectedCardId: null,
    selectedCardData: null,
    selectedFile: null,
    numberInput: null,
    subCodeFileList: [],
  };

  componentDidMount() {
    // get master code id from url

    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    this.props.loadCardCatalog();
    this.props.getSubCode(id);
  }

  // Open the edit card pop up
  handleEditCardModal = (cardId, data, evt) => {
    const { editCardModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editCardModal: !editCardModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedCardId: cardId,
      selectedCardData: data,
    });
  };

  // Open delete card pop up
  handleDeleteCardModal = (cardId, evt) => {
    const { deleteCardModal } = this.state;

    this.setState({
      deleteCardModal: !deleteCardModal,
      selectedCardId: cardId || null,
    });
  };

  // Open add card pop up
  handleAddCardModal = () => {
    const { addCardModal } = this.state;
    this.setState({
      addCardModal: !addCardModal,
    });
  };

  csvToJson = (csvText) => {
    const lines = csvText.split("\n").filter((line) => line.trim() !== ""); // filter out empty lines
    const headers = lines[0].split(",").map((header) => header.trim());
    const result = [];

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i]
        .split(",")
        .map((value) => value.trim().replace(/^"|"$/g, "")); // Remove quotes

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  };

  readCSV = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const csvText = e.target.result;
          const jsonData = this.csvToJson(csvText);
          resolve(jsonData); // Resolve the promise with the data
        } catch (error) {
          reject(error); // Reject the promise if there’s an error
        }
      };
      reader.onerror = (error) => reject(error); // Handle file reading errors
      reader.readAsText(file);
    });
  };

  //Delete Subcode
  submitForm = async (action, values) => {
    const { selectedCardId, selectedFile } = this.state;

    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    var formData = new FormData();
    if (action === "delete") {
      this.props.deleteSubCode({ subcode_id: selectedCardId }).then((res) => {
        if (res && res.data && res.data.status) {
          notifySuccess(res?.data?.message || "Done");
          this.setState({
            ...this.state,
            deleteCardModal: false,
            selectedCardId: null,
          });
          this.props.getSubCode(id);
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
      return;
    }

    if (action === "add") {
      // formData.append("master_code", id);
      // formData.append("subcode", values.number || this.state.selectedFile);

      const data = selectedFile ? await this.readCSV(selectedFile) : [];

      this.props
        .addSubCode({
          partner_id: id,
          subcode: values.number,
          subCodeList: data.map((item) => item.subcode),
        })
        .then((res) => {
          if (res && res.data && res.data.status) {
            notifySuccess(res?.data?.message || "Done");
            this.setState({
              ...this.state,
              addCardModal: false,
              selectedCardId: null,
            });
            this.props.getSubCode(id);
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });

      return;
    }

    // this.props.ADD_UPDATE_SUB_CODES().then((res) => {
    //   console.log(res);
    // });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onloadend = function (e) {
    //   this.setState({
    //     selectedImageSrc: [reader.result],
    //   });
    // }.bind(this);
    this.setState({ selectedFile: file });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "12px" }}>
          {record.sr_no || ""}
        </p>
      ),
    },

    {
      title: "Subcode",
      dataIndex: "subcode",
      key: "subcode",
      render: (_text, record) => (
        <p className="brand-name" style={{ width: "100%", flexGrow: 1 }}>
          {record.subcode || ""}
        </p>
      ),
    },

    {
      title: () => (
        <div style={{ textAlign: "end", marginRight: "3rem" }}> Actions</div>
      ),
      key: "action",

      render: (_text, record) => {
        return (
          <div
            className="btn-actions"
            style={{ textAlign: "end", marginRight: "4rem" }}
          >
            <span
              onClick={(e) => this.handleDeleteCardModal(record.subcode_id, e)}
            >
              Delete
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { card_catalog, subCodesList, match } = this.props;

    const { params } = match;
    const { partnerName, totalCount } = params;

    const {
      addCardModal,
      editCardModal,
      deleteCardModal,
      selectedImageSrc,
      selectedCardData,
    } = this.state;

    let results = [];
    if (subCodesList && subCodesList.length !== 0) {
      for (var i = 0; i < subCodesList.length; i++) {
        results.push({
          sr_no: i + 1,
          ...subCodesList[i],
        });
      }
    }

    return (
      <>
        <BasicLayout>
          <Row className="section-title">
            <Col md={24}>
              <h4>
                {partnerName} - Individual subcodes ({results.length})
              </h4>
            </Col>
          </Row>
          <Row className="card-table" style={{ minHeight: "700px" }}>
            <Table
              sortOrder={false}
              dataSource={results}
              columns={this.columns}
            />
          </Row>

          {editCardModal && selectedCardData && (
            <CustomModal
              className={"signup-setting-modal"}
              visible={editCardModal}
              onClose={this.handleEditCardModal}
            >
              <Form
                size="large"
                initialValues={selectedCardData}
                onFinish={(val) => this.submitForm("edit", val)}
                layout="vertical"
                className="form-login"
              >
                <p className="txt-title">Edit a Subcode</p>
                <div className="custom-form-container">
                  <Form.Item name="brand_sku_code" noStyle>
                    <Input type="hidden" />
                  </Form.Item>

                  <Form.Item
                    name="number"
                    label="Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter number",
                      },
                    ]}
                  >
                    <Input placeholder="Number" type="text" />
                  </Form.Item>

                  <Form.Item
                    name="interest_rate"
                    label="Interest rate"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Interest rate",
                      },
                    ]}
                  >
                    <Input placeholder="Interest rate" type="text" />
                  </Form.Item>

                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label">
                      <div className="custom-file-input">
                        <img
                          src={
                            selectedImageSrc ||
                            selectedCardData?.card_type_image ||
                            `https://via.placeholder.com/100x60?text=Card`
                          }
                          alt="Card Visual"
                        />
                        <input type="file" onChange={this.handleFile} />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  className="text-gray"
                  htmlType="submit"
                  type="primary"
                  block
                >
                  Save
                </Button>
              </Form>
            </CustomModal>
          )}

          {addCardModal && (
            <CustomModal
              className={"signup-setting-modal"}
              visible={addCardModal}
              onClose={this.handleAddCardModal}
            >
              <Form
                size="large"
                onFinish={(val) => this.submitForm("add", val)}
                layout="vertical"
                className="form-login"
              >
                <p className="txt-title">Add a Subcode</p>
                <div className="custom-form-container">
                  <Form.Item
                    name="number"
                    label="Number"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter Subcode",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder=""
                      type="text"
                      value={this.state.numberInput}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          numberInput: e.target.value || null,
                        });
                      }}
                    />
                  </Form.Item>

                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label">
                      <label htmlFor="Partner icon" title="Partner icon">
                        Upload CSV
                      </label>
                      {<p> {this.state?.selectedFile?.name} </p>}
                      <div className="custom-file-input">
                        <img
                          src={
                            selectedImageSrc ||
                            selectedImageSrc?.partner_icon_url ||
                            `https://via.placeholder.com/60x60?text=`
                          }
                          alt="subcode"
                        />

                        <input
                          type="file"
                          accept=".csv"
                          onChange={this.handleFile}
                        />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  className="text-gray"
                  htmlType="submit"
                  type="primary"
                  block
                  disabled={
                    this.state.selectedFile === null &&
                    this.state.numberInput === null
                      ? true
                      : false
                  }
                >
                  Save
                </Button>
              </Form>
            </CustomModal>
          )}

          <CustomModal
            className={"signup-setting-modal"}
            visible={deleteCardModal}
            onClose={this.handleDeleteCardModal}
          >
            <div className="txt-title">Delete this Subcode?</div>
            <span className="txt-info">This change cannot be undone.</span>
            <span className="txt-info">
              This won't affect customers who have already signed up using this
              code.
            </span>

            <Button
              type="secondary"
              block
              onClick={() =>
                this.submitForm("delete", this.state.selectedCardId)
              }
            >
              Delete
            </Button>
          </CustomModal>
        </BasicLayout>

        <div
          style={{
            padding: "1.5rem",
            paddingBottom: "0rem",
            marginTop: "1.5rem",
          }}
        >
          <Button
            className="btn-add-subcode"
            type="primary"
            onClick={() => this.handleAddCardModal(true)}
          >
            Add new subcode
          </Button>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    card_catalog: state.card_catalog,
    subCodesList: state.subCodesList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Subcodes));
