import React from "react";
import { Modal } from "antd";
import { AiOutlineLeft } from "react-icons/ai";
import "./index.less";

const CustomModal = (props) => {
  const { visible, onClose, children, className } = props;
  return (
    <Modal
      className={"custom-modal" + (className ? " " + className : "")}
      title=""
      open={visible}
      footer={null}
      onCancel={onClose}
      closeIcon={
        <span>
          <AiOutlineLeft /> Close
        </span>
      }
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
