import { Button, Col, Form, Input, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// Shows the list of the admin user
class index extends Component {
  state = {
    addModal: false,
    editModal: false,
    deleteModal: false,
    selectedId: null,
    selectedData: null,
  };

  componentDidMount() {
    this.props.loadAdminUser();
  }

  // Open the edit pop up for the admin
  handleEditModal = (id, data) => {
    const { editModal } = this.state;

    this.setState({
      editModal: !editModal,
      selectedId: id,
      selectedData: data,
    });
  };

  // Open the delete pop up for the admin
  handleDeleteModal = (id) => {
    const { deleteModal } = this.state;
    this.setState({
      deleteModal: !deleteModal,
      selectedId: id,
    });
  };

  // Open add pop up for the admin
  handleAddModal = () => {
    const { addModal } = this.state;
    this.setState({
      addModal: !addModal,
    });
    if (this.myFormRef !== undefined && this.myFormRef !== null) {
      this.myFormRef.resetFields();
    }
    setTimeout(() => {
      if (this.myFormRef !== undefined && this.myFormRef !== null) {
        this.myFormRef.resetFields();
      }
    }, 1000);
  };

  // Add, edit and delete admin
  submitForm = (action, values = {}) => {
    const { selectedId } = this.state;
    const { admins } = this.props;
    var formData = {};
    let data = values;
    if (action === "add") {
      let isEmailExits = admins.filter((item) => {
        return item.u_email_id === values.email_id;
      });
      if (isEmailExits.length > 0) {
        return;
      }
      data = {
        email_id: values.email_id_1,
        password: values.password,
      };
    }

    formData = {
      ...data,
    };

    this.props.updateAdminUser(action, selectedId, formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          deleteModal: false,
          editModal: false,
          addModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadAdminUser();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Render admin user data in table
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_text, record) => (
        <Button
          onClick={() => this.handleEditModal(record.admin_id, record)}
          className="text-gray"
          type="primary"
        >
          Change Password
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: "100px",
      render: (_text, record) => {
        const { admins } = this.props;
        return (
          <>
            {admins.length > 1 && (
              <div className="btn-actions">
                <span
                  onClick={(e) => {
                    this.handleDeleteModal(record.admin_id);
                  }}
                >
                  Delete
                </span>
              </div>
            )}
          </>
        );
      },
    },
  ];

  render() {
    const { admins } = this.props;
    const { addModal, editModal, deleteModal, selectedData } = this.state;
    let results = [];
    if (admins && admins.length > 0) {
      results = admins.map((row, index) => ({
        ...row,
        key: "admin_user_" + row.admin_id,
        sr_no: index + 1,
      }));
    }
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>SuperFi Admins</h4>
          </Col>
        </Row>

        <Row
          className="card-table"
          style={{
            height: 902,
          }}
        >
          <Table dataSource={results} columns={this.columns} />
        </Row>

        <div style={{ marginRight: 20 }}>
          <Button
            className="btn-add-card"
            type="primary"
            onClick={() => this.handleAddModal(true)}
          >
            Add new admin
          </Button>
        </div>

        {editModal && selectedData && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editModal}
            onClose={this.handleEditModal}
          >
            <Form
              size="large"
              initialValues={selectedData}
              onFinish={(val) => this.submitForm("edit", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit Admin password</p>
              <p className="txt-info">Change the password for your admin</p>

              <div className="custom-form-container">
                <Form.Item name="brand_sku_code" noStyle>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="New password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input placeholder="New password" type="password" />
                </Form.Item>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save new password
              </Button>
            </Form>
          </CustomModal>
        )}

        {addModal && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={addModal}
            onClose={this.handleAddModal}
          >
            <Form
              size="large"
              onFinish={(val) => this.submitForm("add", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Add an admin</p>
              <p className="txt-info">
                Enter an email and password to create a new admin account
                <hr />
              </p>
              <div className="custom-form-container1">
                <Form.Item
                  name="email_id_1"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                    {
                      validator(_, value) {
                        if (value !== "" && value !== undefined) {
                          let isEmailExits = admins.filter((item) => {
                            return item.u_email_id === value;
                          });
                          if (isEmailExits.length === 0) {
                            let re =
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (re.test(value) === false) {
                              return Promise.reject(
                                new Error("Please enter valid email")
                              );
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Email already linked to an admin")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="Email" type="email" autoComplete="off" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </Form.Item>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this Admin?</p>
          <p className="txt-info">This change cannot be undone</p>
          <div className="divider">
            <hr />
          </div>

          <Button
            type="secondary"
            block
            onClick={() => this.submitForm("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admins: state.admins,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
