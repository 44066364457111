import { notification } from "antd";
// notification methods for failure
export const notifyError = (desc, title = "Error", options = {}) => {
  notification.error({
    message: title,
    description: desc,
    style: {
      top: "60px",
      backgroundColor: "#fef0ef",
      border: "1px solid #fcdbd9",
    },
    ...options,
  });
};
// notification methods for success
export const notifySuccess = (desc, title = "Success", options = {}) => {
  notification.success({
    message: title,
    description: desc,
    style: {
      top: "60px",
      backgroundColor: "#ebf8f2",
      border: "1px solid #cfefdf",
    },
    ...options,
  });
};
