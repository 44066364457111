import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./subcode-used.less";
import moment from "moment";

// It's shows the card catalog info
class subcodesUsed extends Component {
  state = {
    deleteCardModal: false,
    pauseCardModal: false,
    editCardModal: false,
    addCardModal: false,
    selectedCardId: null,
    selectedCardData: null,
    selectedFile: null,
    selectedImageSrc: null,
    isPause: null,
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.props.loadCardCatalog();

    this.props.getUsedSubCode(id);
  }

  // Open the edit card pop up
  handleEditCardModal = (cardId, data, evt) => {
    const { editCardModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editCardModal: !editCardModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedCardId: cardId,
      selectedCardData: data,
    });
  };

  // Open delete card pop up

  handleDeleteCardModal = (cardId, evt) => {
    const { deleteCardModal } = this.state;

    this.setState({
      deleteCardModal: !deleteCardModal,
      selectedCardId: cardId || null,
    });
  };

  handlePauseCardModal = (cardId, isUsed) => {
    const { pauseCardModal } = this.state;
    this.setState({
      pauseCardModal: !pauseCardModal,
      selectedCardId: cardId || null,
      isPause: isUsed === null ? null : isUsed,
    });
  };

  // Open add card pop up
  handleAddCardModal = () => {
    const { addCardModal } = this.state;
    this.setState({
      addCardModal: !addCardModal,
    });
  };

  //Delete Subcode
  submitForm = (action, values) => {
    const { selectedCardId, selectedFile, isPause } = this.state;
    var formData = new FormData();

    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (action === "delete") {
      this.props.deleteSubUsedCode({ user_id: selectedCardId }).then((res) => {
        if (res && res.data && res.data.status) {
          notifySuccess(res?.data?.message || "Done");
          this.setState({
            ...this.state,
            deleteCardModal: false,
            selectedCardId: null,
          });
          this.props.getUsedSubCode(id);
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
      return;
    }

    if (action === "pause" && isPause !== null) {
      this.props
        .pauseSubUsedCode({ user_id: selectedCardId, isPause })
        .then((res) => {
          if (res && res.data && res.data.status) {
            notifySuccess(res?.data?.message || "Done");
            this.setState({
              ...this.state,
              pauseCardModal: false,
              selectedCardId: null,
              isPause: null,
            });
            this.props.getUsedSubCode(id);
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });
      return;
    }
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  convertToCSV = (jsonArray) => {
    const headers = Object.keys(jsonArray[0]);
    const csvRows = [];

    // Add the header row
    csvRows.push(headers.join(","));

    // Add the data rows
    for (const row of jsonArray) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap in quotes
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  downloadCSV = (fileName, jsonData = []) => {
    if (jsonData?.length) {
      const csvContent = this.convertToCSV(jsonData);
      console.log('csvContent', csvContent)

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "13px" }}>
          {record.sr_no || ""}
        </p>
      ),
    },

    {
      title: "Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ width: "230px" }}>
          {record.u_email_id || ""}
        </p>
      ),
    },

    {
      title: "Verified",
      dataIndex: "is_email_verified",
      key: "is_email_verified",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "10px" }}>
          {record.is_email_verified ? "Yes" : "No"}
        </p>
      ),
    },

    {
      title: "Date Joined",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "6px" }}>
          {moment(record.date_created).format("DD/MM/YY") || ""}
        </p>
      ),
    },

    {
      title: "Last Active",
      dataIndex: "last_login_date",
      key: "last_login_date",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "6px" }}>
          {moment(record.last_login_date).format("DD/MM/YY")}
        </p>
      ),
    },

    {
      title: "User Number",
      dataIndex: "user_unique_id",
      key: "user_unique_id",
      render: (_text, record) => (
        <p className="brand-name" style={{ marginLeft: "10px" }}>
          {"#" + record.user_unique_id || ""}
        </p>
      ),
    },

    {
      title: "Subcode Used",
      dataIndex: "_partner_id",
      key: "_partner_id",
      render: (_text, record) => (
        <p className="brand-name">{record._partner_id || "-"}</p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => this.handleDeleteCardModal(record.user_id, e)}
            >
              Delete
            </span>

            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) =>
                this.handlePauseCardModal(record.user_id, record.is_pause)
              }
            >
              {record.is_pause ? "Unpause" : "Pause"}
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { card_catalog, match, subCodeUsedList } = this.props;

    const { params } = match;
    const { partnerName, totalCount } = params;
    const {
      addCardModal,
      editCardModal,
      deleteCardModal,
      selectedImageSrc,
      selectedCardData,
      pauseCardModal,
    } = this.state;

    let results = [];

    if (subCodeUsedList && subCodeUsedList.length !== 0) {
      for (var i = 0; i < subCodeUsedList.length; i++) {
        results.push({
          sr_no: i + 1,
          ...subCodeUsedList[i],
        });
      }
    }
    // results = [
    //   {
    //     sr_no: 1,
    //     subcode: "123123",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    //   {
    //     sr_no: 2,
    //     subcode: "345345",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    //   {
    //     sr_no: 3,
    //     subcode: "2334234",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    //   {
    //     sr_no: 4,
    //     subcode: "5645634",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    //   {
    //     sr_no: 5,
    //     subcode: "1546456",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    //   {
    //     sr_no: 6,
    //     subcode: "3453454",
    //     date_created: "2022-01-26 12:58:05",
    //     date_modified: "2022-02-24 14:06:59",
    //     status: "active",
    //     is_deleted: 0,
    //   },
    // ];

    // if (card_catalog && card_catalog.length > 0) {
    //   var sortedCards = card_catalog.sort((a, b) =>
    //     a.brand_name > b.brand_name ? 1 : b.brand_name > a.brand_name ? -1 : 0
    //   );

    //   var lastBrandName = "";
    //   for (var i = 0; i < sortedCards.length; i++) {
    //     var card = sortedCards[i];
    //     var brandName = card.brand_name;

    //     var key = `brand-${card.card_type_id}`;
    //     if (brandName === lastBrandName) {
    //       key = `card-${card.card_type_id}`;
    //     }

    //     results.push({
    //       sr_no: i + 1,
    //       key: key,
    //       ...card,
    //     });

    //     lastBrandName = brandName;
    //   }
    //   console.log('result', results);
    // }

    return (
      <BasicLayout>
        <div
          role="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.5rem 3rem 0 3rem",
          }}
        >
          <div md={24} role="div">
            <h4>
              {partnerName} -Subcodes used ({results.length})
            </h4>
          </div>
          <div md={23} role="div">
            <div>
              <Button
                // className="btn-add-subcodeUSED"
                style={{ color: "black" }}
                type="primary"
                onClick={() =>
                  this.downloadCSV(
                    `${partnerName}_${moment().format(
                      "DD-MM-YYYY_HH:mm:ss"
                    )}.csv`,
                    results.map((item) => ({
                      sr_no: item.sr_no,
                      user_email: item.u_email_id || "-",
                      used_subcode: item._partner_id,
                    }))
                  )
                }
              >
                Export
              </Button>
            </div>
          </div>
        </div>

        <Row
          className="card-table"
          style={{
            minHeight: "70vh",
          }}
        >
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteCardModal}
          onClose={this.handleDeleteCardModal}
        >
          <div className="txt-title">Delete this Subcode?</div>
          <span className="txt-info">This change cannot be undone.</span>
          <span className="txt-info">
            This won't affect customers who have already signed up using this
            code.
          </span>

          <Button
            type="secondary"
            block
            onClick={() => this.submitForm("delete", this.state.selectedCardId)}
          >
            Delete
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={pauseCardModal}
          onClose={() => this.handlePauseCardModal(null, null)}
        >
          <p className="txt-title">Pause this parnter</p>
          <p className="txt-info">
            Users will not be able to use codes and sub codes for this partner.
          </p>

          <Button
            type="primary"
            block
            onClick={() => this.submitForm("pause", this.state.selectedCardId)}
          >
            {this.state.isPause !== null && this.state.isPause
              ? "Unpause"
              : "Pause"}
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    card_catalog: state.card_catalog,
    subCodeUsedList: state.subCodeUsedList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(subcodesUsed));
