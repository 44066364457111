import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// It's shows the card catalog info
class index extends Component {
  state = {
    editIconModal: false,
    // addCardModal: false,
    selectedIconId: null,
    selectedIconData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    // this.props.loadCardCatalog();
    this.props.loadMerchantIcons();
  }

  // Open the edit card pop up
  handleEditIconModal = (iconId, data, evt) => {
    const { editIconModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editIconModal: !editIconModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedIconId: iconId,
      selectedIconData: data,
    });
  };

  // Add, Edit and Delete Icons
  submitForm = (action, values) => {
    const { selectedIconId, selectedFile } = this.state;
    var formData = new FormData();

    formData.append("action", action);
    formData.append("name", values.name);

    if (selectedFile) {
      if (!selectedFile.type.includes("image/")) {
        notifyError("Please upload image file");
        return false;
      }
      formData.append("file", selectedFile);
    }

    this.props.updateMerchantIconInfo(selectedIconId, formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          editIconModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadMerchantIcons();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "Merchant name",
      dataIndex: "name",
      key: "name",
      render: (_text, record) => (
        <p className="brand-name">{record.name || ""}</p>
      ),
    },
    // {
    //   title: "Raw data",
    //   dataIndex: "raw_data",
    //   key: "raw_data",
    //   render: (_text, record) => (
    //     <p className="brand-name">{record.raw_data || ""}</p>
    //   ),
    // },
    {
      title: "Icon",
      dataIndex: "icon_url",
      key: "icon_url",
      render: (_text, record) => (
        <img
          src={record.icon_url}
          alt=""
          style={{ width: "44px", borderRadius: "70%" }}
        />
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              onClick={(e) =>
                this.handleEditIconModal(
                  record.admin_merchant_icon_id,
                  record,
                  e
                )
              }
            >
              Edit
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { merchant_icons } = this.props;
    const { editIconModal, selectedImageSrc, selectedIconData } = this.state;
    let results = [];
    if (merchant_icons && merchant_icons.length > 0) {
      var sortedIcons = merchant_icons.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      for (var i = 0; i < sortedIcons.length; i++) {
        var icon = sortedIcons[i];
        var key = `icon-${icon.admin_merchant_icon_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...icon,
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Merchants</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        {editIconModal && selectedIconData && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editIconModal}
            onClose={this.handleEditIconModal}
          >
            <Form
              size="large"
              initialValues={selectedIconData}
              onFinish={(val) => this.submitForm("edit", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit a merchant</p>
              <div className="custom-form-container">
                <Form.Item name="brand_sku_code" noStyle>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter merchant name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Merchant name"
                    type="text"
                    value={selectedIconData.name || ""}
                    disabled={true}
                  />
                </Form.Item>

                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="icon" title="Icon">
                      Icon
                    </label>
                    <div className="custom-file-input">
                      <img
                        src={
                          selectedImageSrc ||
                          selectedIconData?.icon_url ||
                          `https://via.placeholder.com/60x60?text=`
                        }
                        alt="Merchant Icon"
                      />
                      <input type="file" onChange={this.handleFile} />
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    merchant_icons: state.merchant_icons,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
