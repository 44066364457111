import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// It's shows the card catalog info
class index extends Component {
  state = {
    deleteCardModal: false,
    editCardModal: false,
    addCardModal: false,
    selectedCardId: null,
    selectedCardData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    this.props.loadCardCatalog();
  }

  // Open the edit card pop up
  handleEditCardModal = (cardId, data, evt) => {
    const { editCardModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editCardModal: !editCardModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedCardId: cardId,
      selectedCardData: data,
    });
  };

  // Open delete card pop up
  handleDeleteCardModal = (cardId, evt) => {
    const { deleteCardModal } = this.state;
    this.setState({
      deleteCardModal: !deleteCardModal,
      selectedCardId: cardId,
    });
  };

  // Open add card pop up
  handleAddCardModal = () => {
    const { addCardModal } = this.state;
    this.setState({
      addCardModal: !addCardModal,
    });
  };

  // Add, Edit and Delete card
  submitForm = (action, values) => {
    const { selectedCardId, selectedFile } = this.state;
    var formData = new FormData();

    if (action === "edit" || action === "add") {
      formData.append("action", action);
      formData.append("brand_name", values.brand_name);
      formData.append("brand_sku_code", values.brand_sku_code);
      formData.append("card_type_name", values.card_type_name);
      formData.append("interest_rate", values.interest_rate);

      if (isNaN(values.interest_rate)) {
        notifyError("Interest rate must be a number");
        return;
      }

      if (parseFloat(values.interest_rate) > 100) {
        notifyError("Interest rate should be less than 100");
        return;
      }

      if (selectedFile) {
        if (!selectedFile.type.includes("image/")) {
          notifyError("Please upload image file");
          return false;
        }
        formData.append("file", selectedFile);
      }
    }
    if (action === "delete") {
      formData.append("action", "delete");
    }

    this.props.updateCardCatalogInfo(selectedCardId, formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          deleteCardModal: false,
          editCardModal: false,
          addCardModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadCardCatalog();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (_text, record) => (
        <p className="brand-name">{record.brand_name || ""}</p>
      ),
    },
    {
      title: "Card name",
      dataIndex: "card_type_name",
      key: "card_type_name",
    },
    {
      title: "Interest rate",
      dataIndex: "interest_rate",
      key: "interest_rate",
      render: (_text, record) => <p>{record.interest_rate || ""}%</p>,
    },
    {
      title: "Card Visual",
      dataIndex: "card_type_image",
      key: "card_type_image",
      render: (_text, record) => (
        <img
          src={record.card_type_image}
          alt="card_visual"
          style={{ width: "50px" }}
        />
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) =>
                this.handleDeleteCardModal(record.card_type_id, e)
              }
            >
              Delete
            </span>
            <span
              onClick={(e) =>
                this.handleEditCardModal(record.card_type_id, record, e)
              }
            >
              Edit
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { card_catalog } = this.props;
    const {
      addCardModal,
      editCardModal,
      deleteCardModal,
      selectedImageSrc,
      selectedCardData,
    } = this.state;

    let results = [];
    if (card_catalog && card_catalog.length > 0) {
      var sortedCards = card_catalog.sort((a, b) =>
        a.brand_name > b.brand_name ? 1 : b.brand_name > a.brand_name ? -1 : 0
      );

      var lastBrandName = "";
      for (var i = 0; i < sortedCards.length; i++) {
        var card = sortedCards[i];
        var brandName = card.brand_name;

        var key = `brand-${card.card_type_id}`;
        if (brandName === lastBrandName) {
          key = `card-${card.card_type_id}`;
        }

        results.push({
          sr_no: i + 1,
          key: key,
          ...card,
        });

        lastBrandName = brandName;
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Card data</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        <div>
          <Button
            className="btn-add-card"
            type="primary"
            onClick={() => this.handleAddCardModal(true)}
          >
            Add new card
          </Button>
        </div>

        {editCardModal && selectedCardData && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editCardModal}
            onClose={this.handleEditCardModal}
          >
            <Form
              size="large"
              initialValues={selectedCardData}
              onFinish={(val) => this.submitForm("edit", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit a card</p>
              <div className="custom-form-container">
                <Form.Item name="brand_sku_code" noStyle>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="brand_name"
                  label="Brand"
                  rules={[
                    {
                      required: true,
                      message: "Please enter brand",
                    },
                  ]}
                >
                  <Input placeholder="Brand name" type="text" />
                </Form.Item>

                <Form.Item
                  name="card_type_name"
                  label="Card name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter card name",
                    },
                  ]}
                >
                  <Input placeholder="Card name" type="text" />
                </Form.Item>
                <Form.Item
                  name="interest_rate"
                  label="Interest rate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Interest rate",
                    },
                  ]}
                >
                  <Input placeholder="Interest rate" type="text" />
                </Form.Item>
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="interest_rate" title="Interest rate">
                      Card visual
                    </label>
                    <div className="custom-file-input">
                      <img
                        src={
                          selectedImageSrc ||
                          selectedCardData?.card_type_image ||
                          `https://via.placeholder.com/100x60?text=Card`
                        }
                        alt="Card Visual"
                      />
                      <input type="file" onChange={this.handleFile} />
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}

        {addCardModal && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={addCardModal}
            onClose={this.handleAddCardModal}
          >
            <Form
              size="large"
              onFinish={(val) => this.submitForm("add", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Add a card</p>
              <div className="custom-form-container">
                <Form.Item name="brand_sku_code" noStyle>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="brand_name"
                  label="Brand"
                  rules={[
                    {
                      required: true,
                      message: "Please enter brand",
                    },
                  ]}
                >
                  <Input placeholder="Brand name" type="text" />
                </Form.Item>

                <Form.Item
                  name="card_type_name"
                  label="Card name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter card name",
                    },
                  ]}
                >
                  <Input placeholder="Card name" type="text" />
                </Form.Item>
                <Form.Item
                  name="interest_rate"
                  label="Interest rate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter interest rate",
                    },
                  ]}
                >
                  <Input placeholder="Interest rate" type="text" />
                </Form.Item>
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="interest_rate" title="Interest rate">
                      Card Visual
                    </label>
                    <div className="custom-file-input">
                      {selectedImageSrc && (
                        <img src={selectedImageSrc} alt="Card Visual" />
                      )}
                      <input type="file" onChange={this.handleFile} />
                      <span>Upload</span>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteCardModal}
          onClose={this.handleDeleteCardModal}
        >
          <p className="txt-title">Delete this card?</p>
          <p className="txt-info">This change cannot be undone</p>
          <div className="divider">
            <hr />
          </div>

          <Button
            type="secondary"
            block
            onClick={() => this.submitForm("delete")}
          >
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    card_catalog: state.card_catalog,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
