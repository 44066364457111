import { Button, Col, Row, Form, Input, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";

// It's shows the card catalog info
class index extends Component {
  state = {
    editIconModal: false,
    // addCardModal: false,
    selectedIconId: null,
    selectedIconData: null,
    selectedFile: null,
    selectedImageSrc: null,
  };

  componentDidMount() {
    // this.props.loadCardCatalog();
    this.props.loadTags();
  }

  // Open the edit card pop up
  handleEditIconModal = (iconId, data, evt) => {
    const { editIconModal } = this.state;

    if (data && data?.brand_name === "") {
      data.brand_name = data.sr_brand_name;
    }

    this.setState({
      editIconModal: !editIconModal,
      selectedFile: null,
      selectedImageSrc: null,
      selectedIconId: iconId,
      selectedIconData: data,
    });
  };

  // Add, Edit and Delete Icons
  submitForm = (action, values) => {
    const { selectedIconId, selectedFile } = this.state;
    var formData = new FormData();

    formData.append("action", action);
    formData.append("name", values.name);

    if (selectedFile) {
      if (!selectedFile.type.includes("image/")) {
        notifyError("Please upload image file");
        return false;
      }
      formData.append("file", selectedFile);
    }

    this.props.updateMerchantIconInfo(selectedIconId, formData).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          editIconModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadMerchantIcons();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        selectedImageSrc: [reader.result],
      });
    }.bind(this);
    this.setState({ selectedFile: file });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "Tag ID",
      dataIndex: "bill_tracker_tag_id",
      key: "bill_tracker_tag_id",
      render: (_text, record) => (
        <p className="brand-name">{record.bill_tracker_tag_id || ""}</p>
      ),
    },
    {
      title: "Tag name",
      dataIndex: "name",
      key: "name",
      render: (_text, record) => (
        <p className="brand-name">{record.name || ""}</p>
      ),
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (_text, record) => {
    //     return (
    //       <div className="btn-actions">
    //         <span
    //           onClick={(e) =>
    //             this.handleEditIconModal(record.bill_tracker_tag_id, record, e)
    //           }
    //         >
    //           Edit
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];

  render() {
    const { tagInfo } = this.props;
    const { editIconModal, selectedImageSrc, selectedIconData } = this.state;
    let results = [];
    if (tagInfo && tagInfo.length > 0) {
      for (var i = 0; i < tagInfo.length; i++) {
        var key = `tag-${tagInfo[i].bill_tracker_tag_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...tagInfo[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Tags</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>

        {/* <div>
          <Button
            className="btn-add-card"
            type="primary"
            onClick={() => this.handleAddCardModal(true)}
          >
            Add new card
          </Button>
        </div> */}

        {editIconModal && selectedIconData && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={editIconModal}
            onClose={this.handleEditIconModal}
          >
            <Form
              size="large"
              initialValues={selectedIconData}
              onFinish={(val) => this.submitForm("edit", val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit a merchant</p>
              <div className="custom-form-container">
                <Form.Item name="brand_sku_code" noStyle>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter merchant name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Merchant name"
                    type="text"
                    value={selectedIconData.name || ""}
                    disabled={true}
                  />
                </Form.Item>

                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label htmlFor="interest_rate" title="Interest rate">
                      Icon
                    </label>
                    <div className="custom-file-input">
                      <img
                        src={
                          selectedImageSrc ||
                          selectedIconData?.icon_url ||
                          `https://via.placeholder.com/60x60?text=`
                        }
                        alt="Merchant Icon"
                      />
                      <input type="file" onChange={this.handleFile} />
                      <span>Edit</span>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    tagInfo: state.tagInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
